import { graphql } from 'gatsby';
import React from 'react';
import ErrorPage, { ErrorPageProps } from 'templates/ErrorPage';
import { SessionExpiryOption } from 'components/SessionExpiry/SessionExpiryWrapper';
import { TescoPageViewEventLevel } from 'helpers/eventTrackingForTesco';
import { usePageTracking } from 'helpers/pageTracking';
import {
  useResetTescoPageLoadTrackingOnRefresh,
  useTescoPageLoadTracking,
  useTescoPageViewTracking,
} from 'helpers/pageTrackingForTesco';

export const query = graphql`
  query {
    csErrorPage(error_id: { eq: "pet-confirmation-session-expired" }) {
      ...ErrorPage
    }
  }
`;

/* istanbul ignore next */
const ConfirmationSessionExpiredPage: React.FC<ErrorPageProps> = ({ data }) => {
  usePageTracking(data.csErrorPage.meta_title);
  useTescoPageLoadTracking();
  useResetTescoPageLoadTrackingOnRefresh();
  useTescoPageViewTracking(TescoPageViewEventLevel.error);

  return (
    <ErrorPage data={data} sessionExpiryOption={SessionExpiryOption.NO_SESSION_EXPIRY} />
  );
};

export default ConfirmationSessionExpiredPage;
